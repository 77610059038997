define("ember-modal-dialog/components/overlay", ["exports", "@ember/component", "@glimmer/component", "@ember/template", "ember-modal-dialog/utils/config-utils", "@ember/template-factory"], function (_exports, _component, _component2, _template, _configUtils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{this.cssClasses}}
    tabindex="-1"
    data-emd-overlay
    {{on "click" this.handleClick}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "KB7tfuhY",
    "block": "[[[11,0],[16,0,[30,0,[\"cssClasses\"]]],[24,\"tabindex\",\"-1\"],[24,\"data-emd-overlay\",\"\"],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"div\",\"on\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/components/overlay.hbs",
    "isStrictMode": false
  });
  class OverlayComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "handleClick", event => {
        this.args.onClickOverlay?.(event);
      });
    }
    get cssClasses() {
      return (0, _template.htmlSafe)(`emd-debug ${_configUtils.isIOS ? 'pointer-cursor' : ''}`);
    }
  }
  _exports.default = OverlayComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OverlayComponent);
});