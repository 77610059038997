define("ember-modal-dialog/components/basic-dialog", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "@ember/string", "ember-modal-dialog/utils/config-utils", "@ember/template-factory"], function (_exports, _component, _object, _service, _utils, _string, _configUtils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberWormhole @to={{this.destinationElementId}}>
    {{#if this.isOverlaySibling}}
      <div
        class="{{this.wrapperClassNamesString}}
          {{this.wrapperClass}}"
      >
        {{#if this.hasOverlay}}
          <EmberModalDialog::Overlay
            class={{this.overlayClassNamesString}}
            @onClickOverlay={{this.onClickOverlay}}
          />
        {{/if}}
        <EmberModalDialogPositionedContainer
          @class={{this.containerClassNamesString}}
          @targetAttachment={{this.targetAttachment}}
          @target={{this.legacyTarget}}
          ...attributes
        >
          {{yield}}
        </EmberModalDialogPositionedContainer>
      </div>
    {{else}}
      <div
        class="{{this.wrapperClassNamesString}}
          {{this.wrapperClass}}"
      >
        {{#if this.hasOverlay}}
          <EmberModalDialog::Overlay
            class={{this.overlayClassNamesString}}
            @onClickOverlay={{ignore-children
              this.onClickOverlay
            }}
          >
            <EmberModalDialogPositionedContainer
              @class={{this.containerClassNamesString}}
              @targetAttachment={{this.targetAttachment}}
              @target={{this.legacyTarget}}
              ...attributes
            >
              {{yield}}
            </EmberModalDialogPositionedContainer>
          </EmberModalDialog::Overlay>
        {{else}}
          <EmberModalDialogPositionedContainer
            @class={{this.containerClassNamesString}}
            @targetAttachment={{this.targetAttachment}}
            @target={{this.legacyTarget}}
            ...attributes
          >
            {{yield}}
          </EmberModalDialogPositionedContainer>
        {{/if}}
      </div>
    {{/if}}
  </EmberWormhole>
  */
  {
    "id": "ArO5SZGf",
    "block": "[[[8,[39,0],null,[[\"@to\"],[[30,0,[\"destinationElementId\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isOverlaySibling\"]],[[[1,\"    \"],[10,0],[15,0,[29,[[30,0,[\"wrapperClassNamesString\"]],\"\\n        \",[30,0,[\"wrapperClass\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"        \"],[8,[39,3],[[16,0,[30,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[30,0,[\"onClickOverlay\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,4],[[17,1]],[[\"@class\",\"@targetAttachment\",\"@target\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"legacyTarget\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[[30,0,[\"wrapperClassNamesString\"]],\"\\n        \",[30,0,[\"wrapperClass\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"        \"],[8,[39,3],[[16,0,[30,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[28,[37,6],[[30,0,[\"onClickOverlay\"]]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,4],[[17,1]],[[\"@class\",\"@targetAttachment\",\"@target\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"legacyTarget\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[18,2,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],[[17,1]],[[\"@class\",\"@targetAttachment\",\"@target\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"legacyTarget\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[18,2,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"ember-wormhole\",\"if\",\"div\",\"ember-modal-dialog/overlay\",\"ember-modal-dialog-positioned-container\",\"yield\",\"ignore-children\"]]",
    "moduleName": "ember-modal-dialog/components/basic-dialog.hbs",
    "isStrictMode": false
  });
  let BasicDialog = _exports.default = (_dec = (0, _service.inject)('modal-dialog'), _dec2 = (0, _object.computed)('attachmentClass', 'containerClass', 'containerClassNames.{[],join}', 'targetAttachmentClass'), _dec3 = (0, _object.computed)('overlayClass', 'overlayClassNames.{[],join}', 'translucentOverlay'), _dec4 = (0, _object.computed)('targetAttachmentClass', 'variantWrapperClass', 'wrapperClass', 'wrapperClassNames.{[],join}'), _dec5 = (0, _object.computed)('overlayPosition'), _dec6 = (0, _object.computed)('targetAttachment'), _class = class BasicDialog extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "containerClassNames", null);
      _defineProperty(this, "overlayClassNames", null);
      _defineProperty(this, "wrapperClassNames", null);
      _defineProperty(this, "destinationElementId", null);
      _defineProperty(this, "translucentOverlay", false);
      _defineProperty(this, "clickOutsideToClose", false);
      _defineProperty(this, "hasOverlay", true);
      _defineProperty(this, "isCentered", true);
      _defineProperty(this, "overlayPosition", null);
      _initializerDefineProperty(this, "modalService", _descriptor, this);
      _defineProperty(this, "variantWrapperClass", 'emd-static');
    }
    init() {
      super.init(...arguments);
      if (!this.destinationElementId) {
        (0, _object.set)(this, 'destinationElementId', this.modalService.destinationElementId);
      }
    }
    get containerClassNamesString() {
      let classNames = this.containerClassNames?.join && this.containerClassNames?.join(' ') || this.containerClassNames;
      return ['ember-modal-dialog', classNames, this.targetAttachmentClass, this.attachmentClass, this.containerClass].filter(className => !(0, _utils.isEmpty)(className)).join(' ');
    }
    get overlayClassNamesString() {
      let classNames = this.overlayClassNames?.join && this.overlayClassNames?.join(' ') || this.overlayClassNames;
      return ['ember-modal-overlay', classNames, this.translucentOverlay ? 'translucent' : null, this.overlayClass].filter(className => !(0, _utils.isEmpty)(className)).join(' ');
    }
    get wrapperClassNamesString() {
      let classNames = this.wrapperClassNames?.join && this.wrapperClassNames?.join(' ') || this.wrapperClassNames;
      return ['ember-modal-wrapper', classNames, this.targetAttachmentClass.replace('emd-', 'emd-wrapper-'), this.variantWrapperClass, this.wrapperClass].filter(className => !(0, _utils.isEmpty)(className)).join(' ');
    }
    get isOverlaySibling() {
      return this.overlayPosition === 'sibling';
    }
    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return `ember-modal-dialog-target-attachment-${(0, _string.dasherize)(targetAttachment)} emd-target-attachment-${(0, _string.dasherize)(targetAttachment)}`;
    }
    didInsertElement() {
      if (!this.clickOutsideToClose) {
        return;
      }
      this.handleClick = ({
        target
      }) => {
        // if the click has already resulted in the target
        // being removed or hidden, do nothing
        if (target.offsetWidth === 0 && target.offsetHeight === 0) {
          return;
        }
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        let modalSelector = '.ember-modal-dialog';
        if (this.stack) {
          modalSelector = '#' + this.stack + modalSelector;
        }

        // if the click is within the dialog, do nothing
        let modalEl = document.querySelector(modalSelector);
        if (modalEl && modalEl.contains(target)) {
          return;
        }
        if (this.onClose) {
          this.onClose();
        }
      };
      const registerDelay = (0, _configUtils.clickHandlerDelay)(this);
      const registerClick = () => document.addEventListener('click', this.handleClick);

      // setTimeout needed or else the click handler will catch the click that spawned this modal dialog
      setTimeout(registerClick, registerDelay);
      if (_configUtils.isIOS) {
        const registerTouch = () => document.addEventListener('touchend', this.handleClick);
        setTimeout(registerTouch, registerDelay);
      }
      super.didInsertElement(...arguments);
    }
    willDestroyElement() {
      document.removeEventListener('click', this.handleClick);
      if (_configUtils.isIOS) {
        document.removeEventListener('touchend', this.handleClick);
      }
      super.willDestroyElement(...arguments);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modalService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "containerClassNamesString", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "containerClassNamesString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "overlayClassNamesString", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "overlayClassNamesString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "wrapperClassNamesString", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "wrapperClassNamesString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isOverlaySibling", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isOverlaySibling"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "targetAttachmentClass", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "targetAttachmentClass"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasicDialog);
});